.blink-s1 {
  animation: blinker 1.3s linear infinite;
}

.blink-s2 {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
