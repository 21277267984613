@tailwind base;
@tailwind components;
@tailwind utilities;

#root * {
  outline: none;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

html,
body,
#root {
  min-height: 100vh;
}
// body {
// overscroll-behavior-y: none;
// }
body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif, 'Source Sans 3', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-col {
  @apply flex;
}

.flex-center-y {
  @apply flex items-center;
}

.flex-center-x {
  @apply flex justify-center;
}

.flex-between {
  @apply flex items-center justify-between;
}

.ellipsis {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}

.bd-gnb .menu :where(li) > :where(*:not(ul)) {
  @apply p-2;
  opacity: 0.5;

  &:hover,
  &.gnb-active {
    opacity: 1;
  }
}

.svg-icon {
  path {
    fill: currentColor;
  }
}
